export const environment: any = {
  "production": true,
  "API": "https://api.rushmypassport.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": true,
  "header": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/rmp_aa_checkout_logo.svg"
  },
  "support": {
    "phone": "8889700069",
    "email": "info@rushmypassport.com",
    "label": ""
  },
  "login": {
    "enabled": false,
    "logo": "https://govworks-email-assets.s3.amazonaws.com/rmp_aa_checkout_logo.svg"
  },
  "terms": {
    "website_url": "https://www.rushmypassport.com",
    "website": "www.rushmypassport.com",
    "website_name": "RushMyPassport.com",
    "website_email": "info@rushmypassport.com"
  },
  "source": {
    "domain": "aaa",
    "main_website": "",
    "promo_code": false,
    "name": "AAA",
    "auto_applied_code": "7becdd50604f"
  },
  "slides": {
    "passport": [    {
      "title": "Select Service Type",
      "icon": {
        "name": "gw-slider-mouse",
        "width": "45px",
        "height": "88px"
      },
      "elements": [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "Expediting cost does not include shipping fees or government fees, which are paid separately to the U.S. Department of State (starting at $190 for adults)."
      ]
    },
    {
      "title": "Complete Online Wizard",
      "icon": {
        "name": "gw-slider-papers",
        "width": "69px",
        "height": "92px"
      },
      "elements": [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      "title": "Get Your Passport Photo",
      "icon": {
        "name": "gw-slider-user",
        "width": "97px",
        "height": "97px"
      },
      "elements": [
        "Every passport application requires a printed government-compliant photo be submitted with your application.",
        "Visit your local AAA branch to obtain government approved passport photos.",
        "<a href='https://aaa.com/office' target='_blank'>Find your local AAA branch.</a>"
      ]
    },
    {
      "title": "Gather Documents and Ship",
      "icon": {
        "name": "gw-slider-fedex",
        "width": "109px",
        "height": "64px"
      },
      "elements": [
        "Review your checklist to ensure you have all required documents.",
        "Follow the instructions for shipping your documents.",
        "Please note: Some services will not require a FedEx shipping label and are shipped via the Passport Acceptance Agent."
      ]
    }]
  },
  "footer": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/rmp_aa_checkout_logo.svg",
    "logo_link": "/",
    "copyright": "RushMyPassport.com, LLC"
  },
  "landing_pages": {
    "passport": {
      "enabled": true,
      "title": "AAA | Passport Expediting",
      "compact_signin": true, 
      "signin": "Returning customer? Sign in to our secure portal to check your application.",
      "sign_in_links": ["visas"],
      "heading": "Simplify your travel documents",
      "description": "AAA members <strong>SAVE 10%</strong> on expedited U.S. Passport & Travel Visa services.<sup>*</sup></br><span style=\"font-size: 11px;\"><sup>*</sup>Savings applicable for RushMyPassport service fees only. Government fees and shipping not included.</span>"
    },
    "visa": {
      "enabled": true,
      "title": "AAA | Visa Expediting",
      "compact_signin": true, 
      "signin": "Returning customer? Sign in to our secure portal to check your application.",
      "sign_in_links": ["passport"],
      "heading": "Traveling soon and need a travel visa?",
      "description": "Receive exclusive AAA rates on expedited travel visa services through RushMyPassport. Save time and a trip to the embassy and consulate. AAA Discount applied at checkout."
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true, 
  "seals": { 
  "truste": "170aae01-cd7c-4ad6-9007-5894464c0962"
  },
  "summary_cta_icon": true,
  "locations_page": {
    "enabled": false
  },
  "tags": {
    "analytics": "UA-2117379-6",
    "ga4": ["G-HN8Y0S5BBW", "G-5S9MVF86SJ"],
    "adwords": "AW-654301254",
    "gtag_conv": "N5bKCJX56ssBEMaw_7cC",
    "mouseflow": "18e26b13-7265-4574-a719-087fc9265e23",
    "tag_manager": "GTM-P8H9XDH",
    "tealium": {
        "account": "aaa",
        "profile": "expeditedtravel",
        "env": "prod"
    }
  },
  "livechat": {
      "enabled": true,
      "license_id": "1073051",
      "group": 13
  }
};